import React from 'react';
import { graphql } from 'gatsby';
import { Box } from 'grommet';

import Layout from '../components/layout/layout';
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs';
import Hero from '../components/hero/hero';
import PortableText from '../components/portable-text/portable-text';
import Lede from '../components/lede/lede';
import Gallery from '../components/gallery/gallery';
import CenterColumn from '../components/center-column/center-column';
import Marker from '../components/marker/marker';

import { slugToUrl } from '../lib/helpers';

export const query = graphql`
  query MinecraftPlaceTemplateQuery($id: String!) {
    place: sanityMinecraftPlace(id: { eq: $id }) {
      id
      title
      xCoordinate
      zCoordinate
      yCoordinate
      shortDescription
      world {
        title
        slug {
          current
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        ...Image
      }
      images {
        ...CaptionedImage
      }
      slug {
        current
      }
    }
  }
`;

const MinecraftPlacePage = ({ data, errors, pageContext }) => {
  const place = data?.place;
  return (
    <Layout>
      <Breadcrumbs
        items={[
          {
            title: 'Minecraft',
            href: '/minecraft'
          },
          {
            title: place.world.title,
            href: slugToUrl(place.world.slug.current, 'minecraftWorld')
          }
        ]}
        section={pageContext.section}
      />
      <Hero {...place}>
        <Marker text="Minecraft Place" />
      </Hero>

      <CenterColumn>
        <Lede text={place.shortDescription} />
        {place.images && <Gallery images={place.images} />}
        <Box width="medium" alignSelf="center">
          {place._rawBody && <PortableText blocks={place._rawBody} />}
        </Box>
      </CenterColumn>
    </Layout>
  );
};

export default MinecraftPlacePage;
