import React from 'react';
import styled from 'styled-components';
import PngIcon from '../icon/pngIcon';

import { Anchor, Box, ResponsiveContext, Text } from 'grommet';

import Icon from '../icon';

const BreadcrumbList = styled.div`
  list-style-type: none;
  padding: 10px 0;
  margin: 0;
  color: #fff;
  flex: true;

  & a {
    color: #fff;
    padding-top: 5px;
  }

  & svg {
    float: left;
    margin-right: 6px;
  }
`;

const Breadcrumbs = ({ items, section }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Box background={section === 'minecraft' ? 'neutral-1' : 'dark-5'}>
      <Box
        width="large"
        alignSelf="center"
        pad={{
          horizontal: size === 'medium' || size === 'small' ? 'medium' : 'none'
        }}
      >
        <BreadcrumbList>
          {items.map(({ href, title }, index) => (
            <Text key={href}>
              <Anchor href={href}>
                {section == 'minecraft' && index === 0 && (
                  <Icon symbol={'minecraft'} />
                )}
                {title}
              </Anchor>
              {index < items.length - 1 && <span> / </span>}
            </Text>
          ))}
        </BreadcrumbList>
      </Box>
    </Box>
  );
};

export default Breadcrumbs;
